.price {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1.6rem;
  letter-spacing: 0.1rem;
  line-height: calc(1 + 0.5 / var(--font-body-scale));
  color: rgb(var(--color-foreground));
}

.price.price--unavailable {
  visibility: hidden;
}

.price--end {
  justify-content: flex-end;
}

.price .price-item {
  margin: 0 1rem 0 0;
}

.price:not(.price--show-badge) .price-item--last:last-of-type {
  margin: 0;
}

@media screen and (min-width: 750px) {
  .price {
    margin-bottom: 0;
  }
}

.price--large {
  font-size: 1.6rem;
  line-height: calc(1 + 0.5 / var(--font-body-scale));
  letter-spacing: 0.13rem;
}

@media screen and (min-width: 750px) {
  .price--large {
    font-size: 1.8rem;
  }
}

.price--sold-out .price__availability,
.price__regular {
  display: block;
}

.price__sale,
.price__availability,
.price .price__badge-sale,
.price .price__badge-sold-out,
.price--on-sale .price__regular,
.price--on-sale .price__availability {
  display: none;
}

.price--sold-out .price__badge-sold-out,
.price--on-sale .price__badge-sale {
  display: inline-flex;
}

.price--on-sale .price__sale {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.price--center {
  display: flex;
  justify-content: center;
}

.price--on-sale .price-item--regular {
  text-decoration: line-through;
  color: rgba(var(--color-foreground), 0.75);
  font-size: 1.3rem;
}

.unit-price {
  display: block;
  font-size: 1.1rem;
  letter-spacing: 0.04rem;
  line-height: calc(1 + 0.2 / var(--font-body-scale));
  margin-top: 0.2rem;
  text-transform: uppercase;
  color: rgba(var(--color-foreground), 0.7);
}
